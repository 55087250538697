<script lang="ts">
  import {fly} from 'svelte/transition';

  import {config} from '$applib/configs/application';
  import {snakeCaseKeys, camelCaseKeys} from '$applib/utils/objects';
  import {parameterizePath} from '$applib/utils/resources/urls';
  import {getCsrfTokenHeader, buildHeaders} from '$applib/utils/headers';
  import {debounce} from '$applib/utils/functions';

  import {Loader} from '$applib/components/loader';
  import {Pagination} from '$applib/components/pagination';

  import type {PaginatedApiResponse} from '$applib/types/request-response';
  import {PatientList, PatientListState} from '../patient-list';

  import type {AbortControllerConfig} from './types';
  import type {PatientSearchItem} from '../patient-list';

  export let appointmentDate: Date;
  export let appointmentTime: string;
  export let staffMemberId: string | number;

  const {path: patientSearchEndpoint} = config.urls.api.patients.search;

  enum State {
    Idle = 'idle',
    Requesting = 'requesting',
  }

  let inputEl: HTMLInputElement;
  let abortControllers: AbortControllerConfig[] = [];
  let response: PaginatedApiResponse<PatientSearchItem>;
  let patients: PatientSearchItem[] = [];
  let error: Record<string, unknown> | null;
  let state = State.Idle;
  let listState: PatientListState;

  $: endpoint = parameterizePath(
    patientSearchEndpoint,
    snakeCaseKeys({
      appointmentStartTime: appointmentTime,
      day: appointmentDate.getUTCDate(),
      month: appointmentDate.getUTCMonth() + 1,
      staffMemberSelected: staffMemberId,
      year: appointmentDate.getFullYear(),
    }),
  );

  function handleKeyUp(event: Event) {
    const value = (event.target as HTMLInputElement).value;

    doPaginatedRequest(value);
  }

  function handlePaginationSelect(event: CustomEvent) {
    const value = inputEl.value;

    doPaginatedRequest(value, event.detail.page);
  }

  function doPaginatedRequest(value: string, page?: number) {
    const params = page ? {q: value, page: `${page}`} : {q: value, page: ''};
    const searchParameters = new URLSearchParams(params);
    const searchString = searchParameters.toString();

    abortOtherControllers(searchString);

    if (value.length === 0) {
      resetState();
    } else {
      getPatients(searchString);
    }
  }

  function abortOtherControllers(searchParams: string) {
    abortControllers
      .filter(({params}) => params !== searchParams)
      .map(({controller}) => controller.abort());

    abortControllers = abortControllers.filter(
      ({params}) => params === searchParams,
    );
  }

  function handleFocus() {
    listState = patients.length > 0 ? PatientListState.Visible : listState;
  }

  function handleBlur() {
    listState = patients.length === 0 ? PatientListState.Hidden : listState;
  }

  function handlePatientSelected() {
    listState = PatientListState.Hidden;
  }

  function getPatients(searchParameters: string) {
    const url = `${endpoint}?${searchParameters}`;
    const controller = new AbortController();
    abortControllers = [
      ...abortControllers,
      {controller, params: searchParameters},
    ];
    state = State.Requesting;

    fetch(url, {
      signal: controller.signal,
      headers: buildHeaders({
        ...getCsrfTokenHeader(),
        Accept: 'application/json',
      }),
    })
      .then((response) => response.json())
      .then(function (apiResponse) {
        response = camelCaseKeys(
          apiResponse,
        ) as PaginatedApiResponse<PatientSearchItem> & object;
        state = State.Idle;
        patients = response.items;
        listState = PatientListState.Visible;
        error = null;
      })
      .catch((response) => {
        if (response.code === DOMException.ABORT_ERR) {
          return;
        }

        error = response;
        console.error(error);
        resetState();
      });
  }

  function resetState() {
    state = State.Idle;
    patients = [];
    listState = PatientListState.Hidden;
  }
</script>

<input
  on:keyup={debounce(handleKeyUp, 300)}
  on:focus={handleFocus}
  on:blur={handleBlur}
  bind:this={inputEl}
  type="text"
  placeholder="Name, surname, phone, email, or patient ID"
/>

{#if error}
  <div>
    <small style="color: red"
      >{Object.keys(error).length > 0
        ? JSON.stringify(error)
        : 'There was a problem getting patients'}</small
    >
  </div>
{/if}

{#if response}
  <div class="u-margin--small--block-end u-text--end">
    <Pagination {response} on:paginationselectpage={handlePaginationSelect} />
  </div>
{/if}

{#if state === State.Requesting}
  <div class="appointment-sidebar u-margin--block-end" in:fly>
    <div class="u-padding--small">
      <small>
        <Loader>Getting patients...</Loader>
      </small>
    </div>
  </div>
{:else}
  <PatientList
    on:patientselected
    on:patientselected={handlePatientSelected}
    state={listState}
    {patients}
  />
{/if}

{#if state !== State.Requesting && response}
  <div class="u-text--end">
    <Pagination {response} on:paginationselectpage={handlePaginationSelect} />
  </div>
{/if}
