// TODO: LB - use correct import syntax without disabling linter
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
type Coloris = typeof import("@melloware/coloris");

// @ts-ignore
let coloris: Pick<Coloris, "coloris" | "init"> = {};
let pendingInits: HTMLInputElement[] = [];
const inittedPickers = new Map<string, HTMLInputElement>();

async function importLib() {
	document.removeEventListener("hs:colorpicker:init", importLib);

	coloris = await import("@melloware/coloris");

	coloris.init();

	document.dispatchEvent(new CustomEvent("hs:colorpicker:libload"));
}

function initColorPicker(inputEl: HTMLInputElement) {
	const config: Partial<Coloris.ColorisOptions> = {
		alpha: true,
		focusInput: false,
		margin: 6,
		selectInput: false,
		swatches: [
			"#000000",
			"#606060",
			"#c0c0c0",
			"#ffffff",
			"#980000",
			"#ff0000",
			"#ff9900",
			"#ffff00",
			"#13ff00",
			"#0effff",
			"#4987e8",
			"#0005ff",
			"#9804ff",
			"#fe03ff",
		],
		themeMode: "auto",
	};
	const inputId = inputEl.id;

	if (inputId && !inittedPickers.has(inputId)) {
		inittedPickers.set(inputId, inputEl);
		coloris.coloris({ ...config, el: inputEl });
	}
}

function handlePendingInits(event: Event) {
	pendingInits = pendingInits.concat(event.target as HTMLInputElement);
}

document.addEventListener("hs:colorpicker:init", importLib);
document.addEventListener("hs:colorpicker:init", handlePendingInits);
document.addEventListener("hs:colorpicker:libload", () => {
	document.addEventListener("hs:colorpicker:init", (event: Event) => {
		initColorPicker(event.target as HTMLInputElement);
	});

	document.removeEventListener("hs:colorpicker:init", handlePendingInits);

	const event = new CustomEvent("hs:colorpicker:init", { bubbles: true });

	for (const toggleEl of pendingInits) {
		toggleEl.dispatchEvent(event);
	}
});
